import { getCurrentRedirectUri } from '@ms/yammer-web-authenticators';
import { insertSplash } from '@ms/yammer-web-support/dist/splash';

const config = require(`./${process.env.YAMMER_ENV}.json`);

config.telemetry.gitRevision = process.env.GIT_REVISION;
config.adal.redirectUri = getCurrentRedirectUri(config.adal.validAuthRedirectUris, config.adal.redirectUri);
config.msal.redirectUri = getCurrentRedirectUri(config.msal.validAuthRedirectUris, config.msal.redirectUri);

insertSplash({ target: document.getElementById('root') });

window.__yammerconfig__ = config;
