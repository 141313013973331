type IsIFrame = () => boolean;
export const isIFrame: IsIFrame = () => window.top !== window.self;

type IsAuthRedirect = () => boolean;
export const isAuthRedirect: IsAuthRedirect = () => window.location.pathname.endsWith('/authredirect');

type IsIFrameAuthRedirect = () => boolean;
export const isIFrameAuthRedirect: IsIFrameAuthRedirect = () => isIFrame() && isAuthRedirect();

type IsMCASContext = () => boolean;
export const isMCASContext: IsMCASContext = () => window.location.pathname.endsWith('/mcas-context');

type GetCurrentRedirectUri = (validAuthRedirectUris: string[], defaultAuthRedirectUri: string) => string;
export const getCurrentRedirectUri: GetCurrentRedirectUri = (
  validAuthRedirectUris: string[],
  defaultAuthRedirectUri: string
) => {
  for (const redirectUri of validAuthRedirectUris) {
    const hostWithProtocol = `${window.location.protocol}//${window.location.host}`;
    if (redirectUri.indexOf(hostWithProtocol) === 0) {
      return redirectUri;
    }
  }

  return defaultAuthRedirectUri;
};
